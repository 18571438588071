import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import PageWrapper from '../components/pageWrapper'

const NotFoundPage = () => (
  <Layout>
    <PageWrapper>
      <Helmet title="Not found" />
      <h1>Page not found</h1>
    </PageWrapper>
  </Layout>
)

export default NotFoundPage
